.ds-list {
  @apply space-y-1;

  li {
    @apply relative pl-4;

    &:before {
      @apply absolute top-[0.75em] left-0 w-2 h-px bg-black;
      content: '';
    }
  }
}

.ds-checked-list {
  @apply pl-5 space-y-5 list-image-[url('/assets/icons/check-list-16.svg')];

  li {
    @apply pl-1;
  }
}
