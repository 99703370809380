@tailwind base;
@import 'base/_base.scss';
@import 'base/_fonts.scss';
@import 'base/_form.scss';
@import 'base/_reset.scss';
@import 'base/_typo.scss';

@tailwind components;
@import 'components/_container.scss';
@import 'components/_cookieconsent.scss';
@import 'components/_dialog.scss';
@import 'components/_list.scss';
@import 'components/_table.scss';
@import 'components/_editor-content.scss';

@tailwind utilities;
@import 'utilities/pseudo-shadow.scss';
