.cc-revoke {
  display: none !important;
}

.cc-banner .cc-btn.cc-allow {
  @apply bg-gray-800 hover:bg-gray-900 hover:no-underline;

  &:hover {
    color: #fff !important;
  }
}
