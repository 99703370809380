/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@include mat.core();

$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto Condensed, sans-serif',
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$digistone-theme: mat.define-light-theme(
  (
    typography: $custom-typography,
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($digistone-theme);
@include mat.dialog-theme($digistone-theme);
