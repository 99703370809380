html,
body {
  @apply h-full;
}

@layer base {
  html {
    font-family: 'Roboto Condensed', 'sans-serif';
    @apply scroll-smooth;
  }
}

svg {
  @apply fill-current w-full h-full;
}

img {
  @apply w-full max-w-full h-auto;
}

button {
  letter-spacing: inherit;
}

b,
strong {
  @apply font-bold;
}

mark {
  @apply bg-transparent text-green-500;
}
