.ds-table {
  @apply min-w-full border-spacing-0 border-separate;
  thead {
    tr {
      @apply first:rounded-tr last:rounded-bl;
    }

    th {
      @apply py-2 px-4 typo-label-sm-bold text-left border-b border-b-gray-800  first:rounded-tl last:rounded-tr first:border-l last:border-r border-t bg-white;
    }
  }

  tbody {
    @apply divide-y;
    tr {
      @apply rounded border-r border-b first:rounded-tl first:rounded-bl first:rounded-tr;
    }

    tr:nth-child(odd) td {
      @apply bg-gray-200;
    }

    tr:nth-child(even) td {
      @apply bg-white;
    }

    td {
      @apply py-3.5 px-4 border-b first:border-l last:border-r whitespace-nowrap;
    }

    tr:last-child {
      td:first-child {
        @apply rounded-bl;
      }

      td:last-child {
        @apply rounded-br;
      }
    }
  }
}

.ds-table--flat {
  thead {
    th {
      @apply bg-gray-200;
    }
  }

  tbody {
    tr {
      @apply odd:bg-white;
    }

    td {
      @apply text-sm;
    }
  }
}
