@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../fonts/RobotoCondensed-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../fonts/RobotoCondensed-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../fonts/RobotoCondensed-Bold.woff2') format('woff2');
}
