.cdk-overlay-dark-backdrop.cdk-overlay-dark-backdrop {
  @apply bg-gray-900 bg-opacity-50;
}

.mat-mdc-dialog-container.mat-mdc-dialog-container {
  --mdc-dialog-container-elevation-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.02);
}

.cdk-global-overlay-wrapper {
  align-items: end !important;
  @media screen and (min-width: 640px) {
    align-items: center !important;
  }
}

.cdk-overlay-pane {
  @apply mb-2;
}
