.typo-h1 {
  @apply text-6xl text-gray-900 font-bold uppercase;
}

.typo-h2 {
  @apply text-5xl text-gray-900 font-bold uppercase;
}

.typo-h3 {
  @apply text-4xl text-gray-900 font-bold uppercase;
}

.typo-h3-flat {
  @apply inline-block text-4xl text-white bg-gray-900 uppercase px-3 py-1 font-normal;
}

.typo-h4 {
  @apply text-3xl text-gray-900 font-bold uppercase;
}

.typo-h5 {
  @apply text-2xl text-gray-900 font-bold uppercase;
}

.typo-h6 {
  @apply text-2xl text-gray-900 font-normal;
}

.typo-label-md {
  @apply text-base font-normal uppercase;
}

.typo-label-md-bold {
  @apply text-base font-bold uppercase;
}

.typo-label-sm {
  @apply text-sm font-normal uppercase;
}

.typo-label-sm-bold {
  @apply text-sm font-bold uppercase;
}

.typo-md {
  @apply text-base font-normal;
}

.typo-sm {
  @apply text-sm font-normal;
}

.typo-xs {
  @apply text-xs font-normal;
}
