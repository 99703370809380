.editor-content {
  h1 {
    @apply typo-h1 mt-8 mb-3 break-words;
  }

  h2 {
    @apply typo-h2 mt-8 mb-3 break-words;
  }

  h3 {
    @apply typo-h3 mt-6 mb-2 break-words;
  }

  h4 {
    @apply typo-h4 mt-4 mb-2 break-words;
  }

  h5 {
    @apply typo-h5 mt-3 mb-2 break-words;
  }

  h6 {
    @apply typo-h6 mt-3 mb-2 break-words;
  }

  ul {
    @apply ds-list my-3;
  }

  a {
    @apply border-b border-current transition duration-200 ease-in-out;

    &:hover {
      @apply border-transparent;
    }
  }

  p {
    @apply mb-3;
  }

  iframe {
    @apply max-w-full;
  }

  > *:first-child {
    @apply mt-0;
  }

  > *:last-child {
    @apply mb-0;
  }

  b,
  strong {
    @apply font-bold;
  }
}
