.form-input,
.form-select,
.form-textarea {
  @apply block w-full bg-gray-200 rounded-sm px-3.5 border-x-0 border-t-0 border-b border-transparent appearance-none placeholder:text-gray-600 text-left focus:outline-0 focus:outline-offset-0 focus:ring-0 focus:border-gray-800;
  &:disabled {
    @apply bg-gray-50 cursor-not-allowed border border-gray-300;
  }

  &.on-gray {
    @apply bg-white;
  }
}

.form-input,
.form-select {
  @apply h-10;
}

.form-select {
  @apply pr-10;
}

.form-input-lg {
  @apply h-12;
}

.form-textarea {
  @apply py-2.5;
}

input[type='date']::-webkit-date-and-time-value {
  @apply text-left;
}

.form-error:not(:focus) {
  @apply bg-error-100 border border-error-500 focus:border-error-500;
}

select {
  @apply bg-none;
}

[type='checkbox'],
[type='radio'] {
  @apply bg-transparent focus:ring-offset-transparent;
}

input[type='radio']:checked,
input[type='checkbox']:checked {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTUuNjMxIDExLjM5IDIuNTUgOC4yOCAxLjUgOS4zMzEgNS42MzEgMTMuNSAxNC41IDQuNTUxIDEzLjQ1OCAzLjUgNS42MyAxMS4zOVoiLz4KPC9zdmc+Cg==');
  background-size: 0.8rem 0.8rem;
}

label {
  a {
    @apply underline;
  }
}
